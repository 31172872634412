// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acerca-de-js": () => import("./../../../src/pages/acerca-de.js" /* webpackChunkName: "component---src-pages-acerca-de-js" */),
  "component---src-pages-asociaciones-js": () => import("./../../../src/pages/asociaciones.js" /* webpackChunkName: "component---src-pages-asociaciones-js" */),
  "component---src-pages-comercializacion-js": () => import("./../../../src/pages/comercializacion.js" /* webpackChunkName: "component---src-pages-comercializacion-js" */),
  "component---src-pages-convenciones-js": () => import("./../../../src/pages/convenciones.js" /* webpackChunkName: "component---src-pages-convenciones-js" */),
  "component---src-pages-convenios-js": () => import("./../../../src/pages/convenios.js" /* webpackChunkName: "component---src-pages-convenios-js" */),
  "component---src-pages-empresas-js": () => import("./../../../src/pages/empresas.js" /* webpackChunkName: "component---src-pages-empresas-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-junta-directiva-js": () => import("./../../../src/pages/junta-directiva.js" /* webpackChunkName: "component---src-pages-junta-directiva-js" */),
  "component---src-pages-ley-agrario-js": () => import("./../../../src/pages/ley-agrario.js" /* webpackChunkName: "component---src-pages-ley-agrario-js" */),
  "component---src-pages-ley-comercial-js": () => import("./../../../src/pages/ley-comercial.js" /* webpackChunkName: "component---src-pages-ley-comercial-js" */),
  "component---src-pages-ley-cuota-pecuaria-js": () => import("./../../../src/pages/ley-cuota-pecuaria.js" /* webpackChunkName: "component---src-pages-ley-cuota-pecuaria-js" */),
  "component---src-pages-ley-sanitaria-js": () => import("./../../../src/pages/ley-sanitaria.js" /* webpackChunkName: "component---src-pages-ley-sanitaria-js" */),
  "component---src-pages-ley-tributaria-y-financiera-js": () => import("./../../../src/pages/ley-tributaria-y-financiera.js" /* webpackChunkName: "component---src-pages-ley-tributaria-y-financiera-js" */),
  "component---src-pages-mensaje-del-presidente-js": () => import("./../../../src/pages/mensaje-del-presidente.js" /* webpackChunkName: "component---src-pages-mensaje-del-presidente-js" */),
  "component---src-pages-noticias-y-eventos-js": () => import("./../../../src/pages/noticias-y-eventos.js" /* webpackChunkName: "component---src-pages-noticias-y-eventos-js" */),
  "component---src-pages-personal-js": () => import("./../../../src/pages/personal.js" /* webpackChunkName: "component---src-pages-personal-js" */),
  "component---src-pages-proyectos-js": () => import("./../../../src/pages/proyectos.js" /* webpackChunkName: "component---src-pages-proyectos-js" */),
  "component---src-pages-registro-de-productores-js": () => import("./../../../src/pages/registro-de-productores.js" /* webpackChunkName: "component---src-pages-registro-de-productores-js" */),
  "component---src-pages-revista-js": () => import("./../../../src/pages/revista.js" /* webpackChunkName: "component---src-pages-revista-js" */),
  "component---src-pages-sectores-js": () => import("./../../../src/pages/sectores.js" /* webpackChunkName: "component---src-pages-sectores-js" */),
  "component---src-pages-sobre-el-registro-js": () => import("./../../../src/pages/sobre-el-registro.js" /* webpackChunkName: "component---src-pages-sobre-el-registro-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */)
}

